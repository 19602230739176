



















































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { IOrderBumpItem } from '@/sparkmembers/views/ProductSetup/components/OrderBumpConfig/types';
import Clipboard from '@/shared/mixins/ClipboardClass';
import ToastHelper from '@/shared/helpers/toast';
import SparkEcommerceApiClient from '@/services/sparkecommerce/api_client.js';
import TrackingHelper from '@/shared/helpers/tracking';

@Component({
  components: {
    MSwitch: () => import('@/shared/components/MSwitch.vue'),
    MConfirmModal: () => import('@/shared/components/MConfirmModal.vue'),
    OrderBumpRelatedProducts: () => import('./OrderBumpRelatedProducts.vue'),
  },
})
export default class OrderBumpListItem extends Mixins(Clipboard) {
  @Prop({ required: true }) orderBumpItem: IOrderBumpItem;

  orderBumpEnabled: boolean = false;
  showRelatedProductsPopover: boolean = false;

  copyToClipboard(value) {
    if (!this.orderBumpEnabled) return;

    const ref = 'link-order-bump';

    try {
      const result = this.clipboardCopy(ref, value);
      if (!result) throw new Error('Exec command failed');

      TrackingHelper.trackOrderBumpLinkCopied(
        this.orderBumpItem.school_product_id,
        this.orderBumpItem.offer_id,
        this.itemsCount
      );
      ToastHelper.successMessage('Link copiado com sucesso');
    } catch (error) {
      ToastHelper.dangerMessage('Erro ao copiar link');
    }
  }

  imageURL(kind) {
    const image = {
      free: 'free',
      common: 'default',
      subscription: 'subscription',
    };

    return require(`@/assets/images/SparkMembers/OffersProduct/${image[kind]}.svg`);
  }

  async deleteOrderBump() {
    try {
      const { id } = this.orderBumpItem;

      await SparkEcommerceApiClient.orderBump.delete(id);

      TrackingHelper.trackOrderBumpDeleted(this.orderBumpItem.core_product_id, this.orderBumpItem.offer_id);
      this.$emit('delete');
    } catch {
      ToastHelper.dangerMessage('Erro ao apagar order bump');
    }

    this.$bvModal.hide(`order-bump-delete-modal-${this.orderBumpItem.id}`);
  }

  trackEnable() {
    const trackMethod = this.orderBumpEnabled ? 'trackOrderBumpEnabled' : 'trackOrderBumpDisabled';

    TrackingHelper[trackMethod](this.orderBumpItem.core_product_id, this.orderBumpItem.offer_id, this.itemsCount);
  }

  get itemsCount() {
    return this.orderBumpItem.items.length;
  }

  get orderBumpLink() {
    return this.orderBumpItem.offer_url;
  }

  mounted() {
    const { enabled } = this.orderBumpItem;

    this.orderBumpEnabled = enabled;
  }

  @Watch('orderBumpEnabled', { immediate: false })
  async onChangeOrderBumpEnabled(orderBumpEnabled) {
    const enableMethod = orderBumpEnabled ? 'enable' : 'disable';

    await SparkEcommerceApiClient.orderBump[enableMethod](this.orderBumpItem.id);
  }
}
